import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";
import InfoTooltip from "components/Card/InfoTooltip";
import { FC, ReactNode } from "react";

interface RowProps {
  title: string;
  subtitle: string | ReactNode;
  isProPlus?: boolean;
}
export const FeaturesRow: FC<RowProps> = ({ title, subtitle, isProPlus }) => {
  return (
    <div className="flex flex-col text-sm">
      <div className="flex items-center">
        <div className="min-w-[30px] h-[30px] flex items-center justify-center mr-[5px]">
          <ZikPro className="fill-primaryPurple dark:fill-purple500 w-[17px] shrink-0" />
        </div>
        <div className="font-bold">{title}</div>
        {isProPlus !== undefined ? (
          <>
            {isProPlus === true ? (
              <InfoTooltip title="Limited to 10 scans a month. Upgrade later for Unlimited!" iconClassName="w-3 h-3" className="ml-1" maxWidth="max-w-[100%]" />
            ) : ""}
            <p className="text-xs text-primaryPurple ml-[auto]">{isProPlus ? "10 scans/month" : "Unlimited"}</p>
          </>
        ) : ""}

      </div>
      <div className="text-xs ml-[35px]">{subtitle}</div>
    </div>
  )
}
interface Props {
  isProPlus?: boolean;
  isProTools: boolean;
}

const ProPlus: FC<Props> = ({ isProPlus, isProTools }) => (
  <div className="flex flex-col gap-2">
    <div className="font-medium text-sm px-2">
      All PRO{isProPlus ? "" : "+"} plan features, and:
    </div>
    {isProTools && !isProPlus ? "" :
      <>
        <FeaturesRow title="500 Best Selling eBay Items" subtitle="Find all the best items" />
        <FeaturesRow title="Bulk Scanner" subtitle="Instantly find thousands of Amazon and Walmart dropship items" />
        <FeaturesRow title="Ali Growth Scanner" subtitle="Easily gather data on trends coming from AliExpress" />
      </>
    }
    <FeaturesRow title="Autopilot" subtitle={<span>Automatically find dropshipping products from AliExpress, Amazon, <br />and Walmart</span>} isProPlus={isProPlus} />
    <FeaturesRow title="Turbo Scanner" subtitle={<span>Automatically discover the best<br /> selling dropship items from<br /> Amazon and Walmart</span>} isProPlus={isProPlus} />
  </div>
);

export default ProPlus;
