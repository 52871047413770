import Plans from "components/Plans/Index";
import PlansComparison from "components/Plans/PlansComparison";
import PlanSwitch from "components/Plans/PlanSwitch";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import usePlans from "hooks/usePlans";
import LoadingPage from "pages/Loading";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import { useEbaySubCountQuery } from "store/api/quickSettingsApi";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { makeQueryParams } from "utils/makeQueryParams";

const ShopifyBillingEbayPlans = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: ebaySubCount, isFetching: countFetching } = useEbaySubCountQuery();
    const user = useDecodeToken();
    const { data } = useGetUserPlanQuery(null);
    const noEbayPlan = ebaySubCount?.result === 0;
    const ebayPlan = data?.result.find((item) => !item.isShopify)
    const paymentStatus = ebayPlan?.status || 0;
    const isTrial = /^true$/i.test(user.active_trail);
    const isCanceled = ebayPlan?.isCanceled;
    const currentPlan = user.package_name || "";
    const isEbayTrial = (paymentStatus === 0 || isCanceled) && !isTrial
    const { plans, handlePlanChange } = usePlans("yearly", false, noEbayPlan);
    const handleSelectPlan = (newVal: TSelectedPlan) => {
        dispatch(onSelectPlan(newVal));
        const redirLink = PATH.managementPanel[isEbayTrial ? "subscriptionPurchase" : "subscriptionUpgrade"]
        navigate(makeQueryParams(redirLink, {
            type: newVal.type,
            ...(newVal.skip && { skip: true })
        }));
    }

    const plansProps = {
        plans,
        currentPlan,
        handleSelectPlan,
        isPayNow: isEbayTrial,
        fromRegistration: noEbayPlan,
    }

    return (
        <LoadingPage loading={countFetching}>
            <div className="flex justify-center items-center flex-col w-full">
                <PlanSwitch
                    activePlan={plans.period}
                    handlePlanChange={handlePlanChange}
                    longSwitchStyles="py-2.5 max-w-[1010px]"
                    showTwoPlans={noEbayPlan}
                />
                <Plans
                    {...plansProps}
                    plansWrapperClassName="justify-center"
                />
                <PlansComparison
                    {...plansProps}
                />
            </div>
        </LoadingPage>
    )
}

export default ShopifyBillingEbayPlans
