import { DAYS_COUNTER_DEADLINE } from "config/constants";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

interface Props {
    isDarkBg?: boolean;
    pulsing?: boolean;
}
interface CountdownTimeLeft {
    days: number;
    hours: number;
    mins: number;
    secs: number;
}
const INITIAL_TIME = {
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
};
const deadline = new Date(DAYS_COUNTER_DEADLINE);

const DaysCounter: FC<Props> = ({ isDarkBg, pulsing }) => {
    const [timeLeft, setTimeLeft] = useState<CountdownTimeLeft>(INITIAL_TIME);
    const hideCounter = dayjs().isAfter(deadline)
    const calculateTimeLeft = () => {
        const current = new Date();
        const difference = deadline.getTime() - current.getTime();
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const mins = Math.floor((difference / 1000 / 60) % 60);
        const secs = Math.floor((difference / 1000) % 60);

        return { days, hours, mins, secs };
    };

    const formatNumber = (num: number) => {
        if (num < 10) {
            return `0${num}`;
        } else {
            return num;
        }
    };
    useEffect(() => {
        const update = () => setTimeLeft(calculateTimeLeft());
        update();

        const timer = setInterval(() => {
            update();
        }, 1000);

        return () => clearInterval(timer);
    }, []);
    const { days, hours, mins, secs } = timeLeft;
    const twoDots = "flex flex-col justify-center gap-[3px] h-[18px]";
    const dot = `w-1 h-1 rounded-full ${isDarkBg ? "bg-grey500" : "bg-grey200"}`;
    const period = "flex w-[26px] flex-col items-center text-center";
    const periodAmount = "text-base leading-[18px] font-normal mb-1"
    const periodTitle = `text-[8px] leading-[12px] font-light ${isDarkBg ? "text-white" : "text-grey900 dark:text-grey100"}`
    return (
        <div
            className={`justify-center gap-2 items-center ${hideCounter ? "hidden" : "flex"} ${isDarkBg ? "text-white" : "dark:text-grey100 text-deepSpace900"} ${pulsing ? "animate-[counterPulse_1.5s_infinite] max-w-[300px] mx-auto" : ""}`}
        >
            <p className={`text-center text-sm leading-[20px] font-medium`}> Offer Ends:</p>
            <div
                className="flex max-w-[116px] justify-start w-fit rounded-[8px]"
            >
                <div className={period}>
                    <span
                        className={`${periodAmount} ${isDarkBg ? "text-[#FFF12F]" : ""}`}
                    >
                        {formatNumber(days)}
                    </span>
                    <span className={periodTitle}>days</span>
                </div>
                <div className={twoDots}>
                    <div className={dot} />
                    <div className={dot} />
                </div>
                <div className={period}>
                    <span
                        className={periodAmount}
                    >
                        {formatNumber(hours)}
                    </span>
                    <span className={periodTitle}>hours</span>
                </div>
                <div className={twoDots}>
                    <div className={dot} />
                    <div className={dot} />
                </div>
                <div className={period}>
                    <span
                        className={periodAmount}
                    >
                        {formatNumber(mins)}
                    </span>
                    <span className={periodTitle}>min.</span>
                </div>
                <div className={twoDots}>
                    <div className={dot} />
                    <div className={dot} />
                </div>
                <div className={period}>
                    <span
                        className={`${periodAmount} text-[#878787]`}
                    >
                        {formatNumber(secs)}
                    </span>
                    <span className={periodTitle}>sec.</span>
                </div>
            </div>
        </div>
    )
}

export default DaysCounter;