import Tooltip from "components/Tooltip";
import { COOKIE_DOMAIN } from "config/constants";
import { useAppSelector } from "hooks/useAppSelector";
import useHover from "hooks/useHover";
import { FC, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { GetAccessToken } from "utils/localStorage";
import BottomMenuDivider from "./BottomMenuDivider";
import Marketplace from "./Marketplace";
import QuickSettings from "./QuickSettings";
import SidebarItemExpandTitle from "./SidebarItemExpandTitle";
import SidebarSubmenu from "./SidebarSubmenu";

export interface SidebarSubmenuProps {
  path: string;
  title: string;
  locked?: boolean;
  translate: string;
  limited?: boolean;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  submenu?: SidebarSubmenuProps[];
}
interface Props {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  IconHovered?: React.FC<React.SVGProps<SVGSVGElement>>;
  path: string;
  title: string;
  translate: string;
  submenu?: SidebarSubmenuProps[];
  quickSettings?: boolean;
  isActive?: boolean;
  handleCustomClick?: () => void;
  width?: string;
  isOpen?: boolean;
  handleChange?: () => void;
  handleClose?: () => void;
  iconStyles: string;
  smIconStyles: string;
  redirect?: string;
  store?: string;
  hasBottomDivider?: boolean | undefined;
  isExpand?: boolean;
  marketplace?: boolean;
}
const SidebarItemContent: FC<Props> = ({
  Icon,
  IconHovered,
  handleCustomClick,
  isOpen,
  handleChange,
  handleClose,
  title,
  translate,
  path,
  submenu,
  width,
  quickSettings,
  iconStyles,
  smIconStyles,
  store,
  isExpand,
  marketplace,

}) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const isSmallSidebar = false;
  const { pathname } = useLocation();
  const { t } = useTranslation(['home']);
  const isActive = pathname.includes(path);
  const iconClassName = `${isHovered || isActive || isOpen ? "fill-primaryPurple dark:fill-purple500 " : "fill-darkBlue dark:fill-grey100"
    } ${isSmallSidebar ? smIconStyles : iconStyles}`;
  const [ignoreClose, setIgnoreClose] = useState(false)


  const handleClick = () => {

    if ((submenu && submenu.length > 0) || quickSettings || marketplace) {
      handleChange && handleChange();
    } else {
      if (handleCustomClick) {
        handleCustomClick();
      }

      return
    }
  };


  return (
    <div className="flex items-center justify-start gap-[12px]">
      <div
        className={`${isSmallSidebar ? "w-[26px] h-[26px]" : "w-9 h-9"} shrink-0 flex items-center justify-center rounded-full relative 
        border-transparent border-2 focus:outline-none ${isOpen
            ? "border-primaryPurple pointer-events-none dark:border-purple500"
            : path === "themeswitch" ? "" : "cursor-pointer hover:border-primaryPurple dark:hover:border-purple500"
          }`}
        ref={hoverRef}
        data-tooltip-id={title}
        onClick={() => { handleClick() }}
      >
        {IconHovered && (isHovered || isActive) ? (
          <IconHovered className={`fill-primaryPurple dark:fill-purple500 ${isSmallSidebar ? smIconStyles : iconStyles}`} />
        ) : (
          <Icon className={iconClassName} />
        )}
        {isOpen && submenu && (
          <SidebarSubmenu
            submenu={submenu}
            width={width}
            handleClose={() => handleClose && handleClose()}
            store={store}
            isExpand={isExpand}
          />
        )}
        {isOpen && quickSettings && (
          <QuickSettings handleClose={() => !ignoreClose && handleClose && handleClose()} setIgnoreClose={setIgnoreClose} isExpand={isExpand} />
        )}
        {isOpen && marketplace && (
          <Marketplace isExpand={isExpand} handleClose={() => handleClose && handleClose()} />
        )}
        {!isOpen && !isExpand ? <Tooltip place="right" title={t(translate)} anchorId={title} /> : ""}

      </div>
      <div className={`duration-300 ${isExpand ? "visible opacity-1" : "invisible opacity-0"}`}>
        <SidebarItemExpandTitle isOpen={isOpen} handleClick={handleClick} title={title} path={path} isActive={isActive} isSmallSidebar={isSmallSidebar} hasSubmenu={submenu && submenu.length > 0} />
      </div>
    </div>


  );
};

const SidebarItem: FC<Props> = (props) => {
  const { accessToken } = useAppSelector((state) => state.user.result);
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
  const token = accessToken || GetAccessToken();

  const [cookies, setCookie] = useCookies(['CAT', 'marketplace']);
  const redirToTitleBuilder = () => {

    setCookie("CAT", token, COOKIE_DOMAIN);
    setCookie("marketplace", dashboardMarketplace, COOKIE_DOMAIN)
  }
  return props.submenu || props.quickSettings || props.marketplace || props.path.includes("logout") || props.path.includes("themeswitch") ? (
    <>
      <SidebarItemContent {...props} />
      {/* Line that divide menu items */}
      {props.hasBottomDivider && <BottomMenuDivider isExpand={props.isExpand} />}
    </>


  ) : props.redirect ? (<a href={props.redirect} onClick={redirToTitleBuilder}>
    <SidebarItemContent {...props} />
  </a>) :
    (
      <>
        <Link to={props.path} >
          <SidebarItemContent {...props} />
        </Link>
        {/* Line that divide menu items */}
        {props.hasBottomDivider && <BottomMenuDivider isExpand={props.isExpand} />}
      </>

    )
};

export default SidebarItem;
