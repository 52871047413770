import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import { formatCurrency, formatNumber } from "utils/formatCurrency";

// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as ScanIcon } from "assets/icons/product-research.svg";
import IconFinderBox from "components/IconFinderBox";
import CompetitorCount from "pages/CompetitorResearch/components/CompetitorCount";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { CURRENCY } from "types/currency";
import { CrEbayList } from "types/services/cr";
import { IconFinderItem } from "types/services/iconFinder";
import { formatDate } from "utils/formatTime";
import { makeQueryParams } from "utils/makeQueryParams";

export const crEbayCols = (
  currency: "$" | "€" | "£" | null,
  dateRangeArr: number[],
  openIconFinder: (item: IconFinderItem) => void,
  handleCountChange: (count: number, id: string) => void,
  handleSort: (column: string) => void,

): ColumnDef<CrEbayList>[] => [
    {
      header: "Product Name",
      accessorKey: "title",
      minSize: 500,
      cell: ({ row: { original } }) => (
        <ProductNameCell
          name={original.title}
          img={original.ebayImage}
          checkKeywords
          width="57px"
          height="59px"
          url={original.productUrl}
        />
      ),
      meta: {
        // @ts-ignore
        align: "justify-start pl-2",
        alignHead: "justify-between",
        customSort: () => handleSort("Title"),
      },
    },
    {
      header: "Upload Date",
      accessorKey: "uploadDate",
      cell: (date) => formatDate(String(date.getValue())),
      meta: {
        customSort: () => handleSort("uploadDate"),
        sortColumnId: "uploadDate",
        align: "justify-start",
      },
    },
    ...(dateRangeArr.includes(7) ? [{
      header: `7 Day Sales`,
      accessorKey: "sevenDaysSales",
      cell: ({ row: { original } }) => (
        <div className="flex flex-col">
          <p className="font-bold">{formatNumber(String(original.sevenDaysSales))}</p>
          <p className="text-[10px]">{formatCurrency(Math.round(original.sevenDaysSalesRevenue), CURRENCY.none, currency)}</p>
        </div>

      ),
      meta: {
        customSort: () => handleSort("sevenDaysSales"),
        sortColumnId: "sevenDaysSales",
        align: "justify-start",
      },
    } as ColumnDef<CrEbayList>] : []),

    ...(dateRangeArr.includes(14) ? [{
      header: `14 Day Sales`,
      accessorKey: "fourteenDaysSales",
      cell: ({ row: { original } }) => (
        <div className="flex flex-col">
          <p className="font-bold">{formatNumber(String(original.fourteenDaysSales))}</p>
          <p className="text-[10px]">{formatCurrency(Math.round(original.fourteenDaysSalesRevenue), CURRENCY.none, currency)}</p>
        </div>
      ),
      meta: {
        customSort: () => handleSort("fourteenDaysSales"),
        sortColumnId: "fourteenDaysSales",
        align: "justify-start",
      },
    } as ColumnDef<CrEbayList>] : []),

    ...(dateRangeArr.includes(21) ? [{
      header: `21 Day Sales`,
      accessorKey: "twentyOneDaysSales",
      cell: ({ row: { original } }) => (
        <div className="flex flex-col">
          <p className="font-bold">{formatNumber(String(original.twentyOneDaysSales))}</p>
          <p className="text-[10px]">{formatCurrency(Math.round(original.twentyOneDaysSalesRevenue), CURRENCY.none, currency)}</p>
        </div>
      ),
      meta: {
        customSort: () => handleSort("twentyOneDaysSales"),
        sortColumnId: "twentyOneDaysSales",
        align: "justify-start",
      },
    } as ColumnDef<CrEbayList>] : []),

    ...(dateRangeArr.includes(30) ? [{
      header: `30 Day Sales`,
      accessorKey: "thirtyDaysSales",
      cell: ({ row: { original } }) => (
        <div className="flex flex-col">
          <p className="font-bold">{formatNumber(String(original.thirtyDaysSales))}</p>
          <p className="text-[10px]">{formatCurrency(Math.round(original.thirtyDaysSalesRevenue), CURRENCY.none, currency)}</p>
        </div>
      ),
      meta: {
        customSort: () => handleSort("thirtyDaysSales"),
        sortColumnId: "thirtyDaysSales",
        align: "justify-start",
      },
    } as ColumnDef<CrEbayList>] : []),
    {
      header: "Total Sold",
      accessorKey: "quantitySold",
      cell: ({ row: { original } }) => (
        <div className="flex flex-col">
          <p className="font-bold">{formatNumber(String(original.quantitySold))}</p>
          <p className="text-[10px]">{formatCurrency(Math.round(original.revenue), CURRENCY.none, currency)}</p>
        </div>
      ),
      meta: {
        customSort: () => handleSort("quantitySold"),
        sortColumnId: "quantitySold",
        align: "justify-start",
      },
    },
    {
      header: "Price",
      accessorKey: "currentPrice",
      cell: (price) =>
        formatCurrency(String(price.getValue()), CURRENCY.none, currency),
      meta: {
        customSort: () => handleSort("currentPrice"),
        sortColumnId: "currentPrice",
        align: "justify-start",
      },
    },
    {
      header: () => <CompetitorResearch className="fill-darkBlue dark:fill-grey100 w-5.5 h-5.5" />,
      minSize: 50,
      accessorKey: "totalRowsFound",
      enableSorting: false,
      cell: ({
        row: {
          original: { title, itemID },
        },
      }) => <CompetitorCount title={title} ebayId={itemID}
        handleCountChange={(count) => handleCountChange(count, itemID.toString())} />,
      meta: {
        tooltipTitle: "Number of Competitors",
        align: "justify-start",
      },
    },
    {
      header: () => <Box className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "ebayImage",
      minSize: 50,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <IconFinderBox
          openIconFinder={openIconFinder}
          isGreen={original.isGreen}
          isSimilar={original.isSimilar}
          sales={original.selectedColumnSales}
          currentPrice={original.currentPrice}
          title={original.title}
          itemID={original.itemID}
          images={original.ebayImage}
          productUrl={original.productUrl}
        />
      ),
      meta: {
        tooltipTitle:
          "Use the item finder to locate products from your chosen supplier. To switch suppliers, go to Quick Settings on the left-side menu",
        alignHead: "justify-center",
        align: "justify-start",
      },
    },
    {
      header: () => <ScanIcon className="fill-darkBlue dark:fill-grey100 w-[17px] h-[17px]" />,
      accessorKey: "competitorCount",
      minSize: 50,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <Link
          to={makeQueryParams(PATH.productResearch.ebay, { search: encodeURIComponent(original.title) })}

          target="_blank"
          rel="noreferrer"
        >
          <HoveredIcon iconClassName="w-[17px] h-[17px]" Icon={ScanIcon} title="Analyze Product" />
        </Link>
      ),
      meta: {
        tooltipTitle: "Search the product title using the Product Research Tool",
        alignHead: "justify-center"
      },
    },
  ];
