import PlanPeriodRadio from "components/Plans/PlanPeriodRadio"
import { useAppDispatch } from "hooks/useAppDispatch"
import { FC, useState } from "react"
import { useLocation } from "react-router-dom"
import { onSelectPlan } from "store/slices/planSlice"
import { PLAN_NAME } from "types/plans"
import { getPlanDetails } from "utils/planUtils"

interface Props {
    handleRefetchUpgrade: (type: string) => void;
}

const LimitUpgradeSwitch: FC<Props> = ({ handleRefetchUpgrade }) => {
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const fromZikPro = search.includes("from=ZikPro");
    const fromPE = search.includes("from=PE");
    const isYearlyPlan = search.includes(PLAN_NAME.EnterpriseYearly) || search.includes(PLAN_NAME.ProPlusYearly) || search.includes(PLAN_NAME.ProYearly);
    const initialPeriod = (fromPE || fromZikPro) ? (isYearlyPlan ? "yearly" : "monthly") : null
    const [activePeriod, setActivePeriod] = useState<string | null>(initialPeriod)
    const onSelPeriodChange = (period: string) => {
        const monthlySelected = period === "monthly"
        const isPro = search.includes("Starter");
        const isProPlus = search.includes("Standard");
        const zikProPlanType = PLAN_NAME[monthlySelected ? "EnterpriseMonthly" : "EnterpriseYearly"];
        const PEPlanType = PLAN_NAME[monthlySelected ?
            (isPro ? "ProMonthly" : isProPlus ? "ProPlusMonthly" : "EnterpriseMonthly") :
            (isPro ? "ProYearly" : isProPlus ? "ProPlusYearly" : "EnterpriseYearly")
        ];
        const planType = fromZikPro ? zikProPlanType : PEPlanType;
        const plan = getPlanDetails(planType);
        setActivePeriod(period);
        dispatch(onSelectPlan({
            type: planType,
            planName: plan?.planName || "",
            planPrice: plan?.planPrice || 0,
            skip: false
        }));

        handleRefetchUpgrade(planType);
    }
    return activePeriod && (fromZikPro || fromPE) && !search.includes("LT") ? (
        <div className={`flex justify-end gap-2 w-full mt-2`}>
            {["monthly", "yearly"].map((itm, i) => (
                <PlanPeriodRadio key={i} period={itm} active={activePeriod} onPeriodSelect={onSelPeriodChange} />
            ))}
        </div>) : (<div />)
}

export default LimitUpgradeSwitch