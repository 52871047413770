import { YOUTUBE_BASE } from "config/constants";

export const helpVideos = {
  pr: [
    {
      title: "eBay Product Research Tutorial",
      source: `${YOUTUBE_BASE}/9Xjm9uUsmjQ`,
    },
    {
      title: "Product Research - Title Analytics tutorial",
      source: `${YOUTUBE_BASE}/MjiuAGTdvxU`,
    },
    {
      title: "Product Research - Price Analytics tutorial",
      source: `${YOUTUBE_BASE}/eQFSJxNnZ_g`,
    },
  ],
  prAli: [
    {
      title: "AliExpress Product Research tutorial",
      source: `${YOUTUBE_BASE}/BmAFTYfOagM`,
    },
  ],
  cr: [
    {
      title: "Competitor Research Tutorial",
      source: `${YOUTUBE_BASE}/cfwrLtm9g-w`,
    },
  ],
  crAli: [
    {
      title: "Competitor Research - AliExpress Tutorial",
      source: `${YOUTUBE_BASE}/fYVI9yDzyio`,
    },
  ],
  category: [
    {
      title: "Category Research tutorial",
      source: `${YOUTUBE_BASE}/aejcnQPHjn8`,
    },
  ],
  titleBuilder: [
    {
      title: "",
      source: "https://player.vimeo.com/video/751142400?h=7f3130ec22",
    },
  ],
  bulkScanner: [
    {
      title: "Bulk scanner tutorial",
      source: `${YOUTUBE_BASE}/FeLqgCgFe2o`,
    },
  ],
  bestSellingItems: [
    {
      title: "500 best selling eBay items tutorial",
      source: `${YOUTUBE_BASE}/hYA8mvGKAg8`,
    },
  ],
  aliGrowth: [
    {
      title: "Ali Growth Scanner Tutorial",
      source: `${YOUTUBE_BASE}/xuOrYp3eS4Q`,
    },
  ],
  turboScanner: [
    {
      title: "Turbo scanner tutorial - Amazon",
      source:
        "https://player.vimeo.com/video/891037694?h=6479cf9b73&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Turbo scanner tutorial - Walmart",
      source: `${YOUTUBE_BASE}/1sCMqzIukEw`,
    },
  ],
  autopilot: [
    {
      title: "Autopilot tutorial - Amazon",
      source: `${YOUTUBE_BASE}/QB30NAKr3_w`,
    },
    {
      title: "Autopilot tutorial - AliExpress",
      source: `${YOUTUBE_BASE}/Is4LjP14XWc`,
    },
    {
      title: "Autopilot tutorial - Walmart",
      source: `${YOUTUBE_BASE}/NP-7bm07qjs`,
    },
  ],
  myCompetitors: [
    {
      title: "How to save competitor to watchlist tutorial",
      source: `${YOUTUBE_BASE}/naYmOuGI6-A`,
    },
  ],
  myProducts: [
    {
      title: "How to create new folders tutorial",
      source: `${YOUTUBE_BASE}/fefmvGbOVTY`,
    },
    {
      title: "How to upload items to lister tools - CSV file - tutorial",
      source:
        "https://player.vimeo.com/video/894474409?h=f5ecb0dcda&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  quickSettings: [
    {
      title: "Quick settings tutorial",
      source: `${YOUTUBE_BASE}/Lkj-emtblCU`,
    },
    {
      title: "How to set up Breakeven tutorial",
      source:
        "https://player.vimeo.com/video/888615525?h=7fc01690cb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ],
  settings: [
    {
      title: "",
      source: "https://player.vimeo.com/video/916638199?h=cf665cdbb7",
    },
  ],
  savedSearches: [
    {
      title: "How to save product research searches tutorial",
      source: `${YOUTUBE_BASE}/Qi8AmGg2fYI`,
    },
  ],
  affiliateManagement: [
    {
      title: "How to become an affiliate - Tutorial",
      source: `${YOUTUBE_BASE}/f1ja6aqlOBQ`,
    },
  ],
  managePayments: [
    {
      title: "How to upgrade subscription tutorial",
      source: `${YOUTUBE_BASE}/menjbXB42fU`,
    },
  ],
  storeAnalytics: [
    {
      title: "How to connect your store Tutorial",
      source: `${YOUTUBE_BASE}/N1TBrSoMHSE`,
    },
    {
      title: "Store Analytics statistics - Tutorial",
      source: `${YOUTUBE_BASE}/t5ttSlrKcYo`,
    },
  ],
  manualScanner: [
    {
      title: "Manual Scanner – Item finder tutorial – Amazon",
      source: `${YOUTUBE_BASE}/ISmz_REY8CY`,
    },
    {
      title: "Manual Scanner - AliExpress Tutorial",
      source: `${YOUTUBE_BASE}/YH_dOVbhQvg`,
    },
    {
      title: "Manual Scanner - Walmart Tutorial",
      source:
        "https://player.vimeo.com/video/894077335?h=f924ee5be4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Manual Scanner Alibaba Tutorial",
      source: `${YOUTUBE_BASE}/OecRvbfskas`,
    },
  ],
};

export const shopifyHelpVideos = {
  productExplorer: [
    {
      title: "Shopify Product Explorer tutorial",
      source: "https://www.youtube.com/embed/SC1hiH6WA5M?si=mUjo2bO24f6HS1NQ",
    },
  ],
  storeFinder: [
    {
      title: "Shopify Store Finder tutorial",
      source: "https://www.youtube.com/embed/cz4RYF20alM?si=xphJUbZ0Jejk4crs",
    },
  ],
  salesTracker: [
    {
      title: "Shopify Store Sales Tracker tutorial",
      source: "https://www.youtube.com/embed/AFntugi3ACI?si=O_DOf-CQpQPS0ef3",
    },
  ],
};
