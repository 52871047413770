import Loading from "components/Loading";
import Tooltip from "components/Tooltip";
import useHover from "hooks/useHover";
import { FC, useId } from "react";
interface Props {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  HoveredIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  className?: string;
  width?: string;
  variant?: "outlined" | "default";
  height?: string;
  iconColor?: string;
  isLoading?: boolean;
  title?: string;
  loadingClassName?: string;
  iconClassName?: string;
  hoveredIconClassName?: string;
  handleClick?: () => void;
  tooltipClassName?: string
}

const HoveredIcon: FC<Props> = ({
  Icon,
  width,
  height,
  HoveredIcon,
  variant = "default",
  iconClassName,
  loadingClassName,
  hoveredIconClassName,
  className,
  isLoading,
  iconColor,
  handleClick,
  title,
  tooltipClassName
}) => {
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>();
  const id = useId();

  return (
    <button
      ref={hoverRef}
      className={`hover:border-primaryPurple dark:hover:border-purple500 border-2 flex justify-center ${width || "w-8"
        } ${height || "h-8"} items-center ${variant === "outlined"
          ? "border-darkBlue hover:bg-primaryPurple dark:hover:bg-purple500 cursor-pointer dark:border-grey100"
          : "border-transparent"
        } rounded-full ${className || ""} group`}
      onClick={isLoading ? () => { } : handleClick}
      data-tooltip-id={id}
    >
      {isLoading ? (
        <Loading
          fill="fill-smoothGreen dark:fill-paradiseBlue"
          className={`mx-auto ${loadingClassName}`}
          width={16}
          height={16}
        />
      ) : HoveredIcon && isHovered ? (
        <HoveredIcon
          className={`${variant === "outlined" ? "fill-white" : "fill-primaryPurple dark:fill-purple500"
            } ${hoveredIconClassName || ""}`}
        />
      ) : (
        <Icon
          className={`${variant === "outlined"
            ? "group-hover:fill-white"
            : "group-hover:fill-primaryPurple dark:group-hover:fill-purple500"

            } ${iconColor || "fill-darkBlue dark:fill-grey100"} ${iconClassName || ""}`}
        />
      )}
      {title && <Tooltip anchorId={id} title={title} className={tooltipClassName} />}
    </button>
  );
};

export default HoveredIcon;
