import { shopifyPlanComparison } from "assets/data/plans/shopifyPlans";
import { FC } from "react";
import { TSelectedPlan } from "types/plans";
import ShopifyPlanComparisonFooter from "./ComparisonFooter";
import PlansComparisonItem from "./ComparisonItem";

interface Props {
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isRegFunnel?: boolean;
    isPayNow?: boolean;
    mobileActivePlan?: number;
    wrapperStyles?: string;
    fromNoSub?: boolean;
}

const ShopifyPlansComparison: FC<Props> = ({ handleSelectPlan, isRegFunnel, isPayNow, wrapperStyles, mobileActivePlan, fromNoSub }) => {
    const flexStyles = "flex items-center font-bold w-full justify-center"
    const hiddenCol = mobileActivePlan === 0 ? "monthly" : "yearly"

    return (
        <div className={` w-full flex justify-center dark:text-darkBlue ${wrapperStyles}`}>
            <div className="max-w-[901px] max-lg:max-w-[556px] max-sm:max-w-[323px] w-full mt-5">
                <div className="shadow-shadow3 z-10 rounded-[15px] overflow-hidden relative z-[20]">
                    <div className="h-[49px] rounded-t-[15px] bg-darkBlue text-white flex justify-between">
                        <div className={`${flexStyles} max-w-[261px]`}>Quick Look</div>
                        <div className={`${flexStyles} max-w-[292px] max-lg:max-w-[163px] ${hiddenCol === "monthly" ? "max-sm:max-w-[99px]" : "max-sm:hidden"}`}>Monthly</div>
                        <div className={`${flexStyles} max-lg:max-w-[163px] ${hiddenCol === "yearly" ? "max-sm:max-w-[99px]" : "max-sm:hidden"}`}>Yearly</div>
                    </div>
                    {shopifyPlanComparison.map((item, i, row) => (
                        <PlansComparisonItem
                            key={item.id}
                            hideBottomBorder={i + 1 === row.length}
                            {...item}
                        />
                    ))}
                </div>
                <ShopifyPlanComparisonFooter fromNoSub={fromNoSub} isRegFunnel={isRegFunnel} handleSelectPlan={handleSelectPlan} isPayNow={isPayNow} hiddenCol={hiddenCol} />
            </div>
        </div>
    )
}

export default ShopifyPlansComparison