import {
  CrownIcon1,
  DiamondHalfIcon,
  DiamondIcon,
  SupplierIcon1,
  SupplierIcon2
} from "assets/icons/babaIcons";
import Loading from "components/Loading";
//import Table from "components/Table";
import DrawerList from "components/DrawerList";
import { CJ_DROPSHIPPING } from "config/constants";
import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import {
  useFindAliItemsQuery,
  useFindBabaItemsQuery,
  useFindCjdItemsQuery,
  useFindWalItemsQuery,
  useLazyGetAliseekProductQuery
} from "store/api/iconFinderApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import {
  FindBabaOffer,
  IconFinderItem,
  MarketPlaceSimilarItemsList
} from "types/services/iconFinder";
import {
  calculateAlibabaProfit,
  calculateAliexpressProfit,
  calculateCjdProfit,
  calculateWalmartProfit
} from "utils/calculateProfit";
import cutLongTitle from "utils/cutLongTitle";
import { SaveItem } from ".";

interface Props {
  item: IconFinderItem;
  onSaveItem: (item: SaveItem) => void;
  helpers: ReturnType<useModal>
  site?: string;
  saveLoading?: boolean
  isShopify?: boolean;
}
const AliexpressSimilarItems: FC<Props> = ({
  item,
  onSaveItem,
  helpers,
  site,
  saveLoading,
  isShopify
}) => {
  const [aliSeekLoading, setAliseekLoading] = useState(false);
  const { data: settings } = useGetSettingsQuery();
  const [similarList, setSimilarList] = useState<MarketPlaceSimilarItemsList[]>(
    []
  );
  const { data: aliItems, isFetching: aliLoading } = useFindAliItemsQuery(
    item.img,
    {
      skip: site !== "Ali" || !helpers.isOpen,
    }
  );
  const { data: cjdItems, isFetching: cjdLoading } = useFindCjdItemsQuery(
    item.img,
    {
      refetchOnMountOrArgChange: true,
      skip: site !== "Cjd",
    }
  );

  const { data: babaItems, isFetching: babaLoading } = useFindBabaItemsQuery(
    item.img,
    {
      skip: site !== "Baba" || !helpers.isOpen,
    }
  );

  const { data: walItems, isFetching: walLoading } = useFindWalItemsQuery(
    item.id,
    {
      refetchOnMountOrArgChange: true,
      skip: site !== "WAL",
    }
  );

  const [getAliSeekProductId] =
    useLazyGetAliseekProductQuery();

  useEffect(() => {
    if (site === "Ali" && aliItems) {
      setSimilarList([]);
      if (aliItems?.result?.data) {
        aliItems?.result?.data?.forEach((aliItem) => {
          const newItem = {
            title: cutLongTitle(aliItem.productTitle, 100),
            img: aliItem.productImgUrl,
            url: aliItem.url,
            price: aliItem.price.toString(),
            id: aliItem.productId,
            sales: aliItem.orderCnt,
            profit: calculateAliexpressProfit(
              aliItem.price.toString(),
              item.price,
              settings?.result.breakEven || 0
            ),
          }

          if (aliItems.result.apiName.toLowerCase() === "aliseek") {
            setAliseekLoading(true);
            getAliSeekProductId(aliItem.url)
              .unwrap()
              .then((data) => {
                newItem.id = data.result.productId
                newItem.url = data.result.url
                setSimilarList((current) => [...current, newItem]);
                setAliseekLoading(false);
              })
              .catch(() => {
                setSimilarList((current) => [...current, newItem]); setAliseekLoading(false);
              })
          }
          else {
            setSimilarList((current) => [...current, newItem]);
            setAliseekLoading(false);
          }

        }
        )
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliItems]);

  const getBabaDetails = (prod: FindBabaOffer) => {
    const SupplierIcon = prod.supplier.assessedSupplier ? (
      <SupplierIcon1 />
    ) : (
      <SupplierIcon2 />
    );

    // eslint-disable-next-line eqeqeq
    const CrownIcon = prod.company.tradeAssurance == "" ? "" : <CrownIcon1 />;
    const rating = parseInt(prod.company.transactionLevel || "0") / 2;
    const isInteger = Number.isInteger(rating);
    const roundRating = Math.floor(rating);
    let createDiamonds =
      rating > 0
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ? [...Array(roundRating)].map((e, i) => <DiamondIcon key={i} />)
        : [];
    createDiamonds = isInteger
      ? createDiamonds
      : createDiamonds.concat(<DiamondHalfIcon />);

    const renderDiamonds = rating > 0 ? createDiamonds : "";
    const detailsObj = {
      supplier: SupplierIcon,
      crown: CrownIcon,
      diamonds: renderDiamonds,
      year: prod.supplier.supplierYear,
    };

    return detailsObj;
  };

  useEffect(() => {
    if (site === "Baba" && babaItems && babaItems?.result?.data?.offerList) {
      //const price = Number( babaItem.tradePrice.prsice.split(" ")[0].split("$")[1])
      setSimilarList(
        babaItems?.result.data.offerList.map((babaItem) => ({
          title: cutLongTitle(babaItem.information.title, 100),
          price:
            babaItem.tradePrice.price.split(" ")[0].split("$")[1],
          img: babaItem.image.mainImage,
          url: babaItem.information.productUrl,
          id: String(babaItem.information.id),
          profit:
            calculateAlibabaProfit(
              babaItem.tradePrice.price,
              item.price,
              settings?.result.breakEven || 0
            ),
          details: getBabaDetails(babaItem),
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [babaItems]);

  useEffect(() => {
    if (site === "Cjd" && item.img !== "" && cjdItems && cjdItems?.result.data.data) {
      setSimilarList(
        cjdItems?.result.data.data.map((cjItem) => ({
          title: cutLongTitle(cjItem.nameEn, 100),
          img: cjItem.bigImg,
          url: `${CJ_DROPSHIPPING}/product/${cjItem.nameEn.replaceAll(" ", "-") + "-p-" + cjItem.categoryId
            }.html`,
          price: cjItem.sellPrice,
          id: cjItem.id,
          profit: calculateCjdProfit(
            cjItem.sellPrice,
            item.price,
            settings?.result.breakEven || 0
          ),
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cjdItems]);


  useEffect(() => {
    if (site === "WAL" && walItems && walItems?.result.item) {
      const walItem = walItems?.result.item;
      const newItem: MarketPlaceSimilarItemsList = {
        title: cutLongTitle(walItem.amazonTitle, 100),
        img: walItem.amazonImageURL,
        url: `https://walmart.com/ip/${walItem.asin}`,
        price: walItem.amazonFormattedPrice.toString(),
        id: walItem.asin,
        profit: calculateWalmartProfit(
          item.price,
          walItem.amazonFormattedPrice,
          settings?.result.breakEven || 0
        ),
      }
      setSimilarList(
        [newItem]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walItems]);

  const similarLoading = aliLoading || cjdLoading || babaLoading || walLoading || aliSeekLoading;

  const onSaveClick = (similarItem: MarketPlaceSimilarItemsList) => {

    onSaveItem({
      title: similarItem.title,
      price: Number(similarItem.price),
      pictureUrl: similarItem.img,
      asin: similarItem.id,
      profit: similarItem.profit,
    });
  };

  return similarLoading ? (
    <div className="flex justify-center items-center h-28">
      <Loading fill="fill-primaryPurple dark:fill-purple500" width={48} height={48} />
    </div>
  ) : similarList.length === 0 ? <div className="w-full flex items-center justify-center h-[80px] border-[2px] border-darkBlue rounded-10 border-secondary dark:border-grey100 dark:text-grey100 text-sm font-medium">No similar products found</div> : (
    <DrawerList items={similarList} onSaveClick={onSaveClick} site={site} isShopify={isShopify} saveLoading={saveLoading} />
  )
};

export default AliexpressSimilarItems;
