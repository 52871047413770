import BillingBackButton from "components/Plans/BackButton";
import PlansDiscountTxt from "components/Plans/PlansDiscountTxt";
import PlanSwitch from "components/Plans/PlanSwitch";
import Toaster from "components/Toast";
import useDecodeToken from "hooks/useDecodeToken";
import usePlans from "hooks/usePlans";
import BillingHeader from "pages/Shopify/Billing/components/BillingHeader";
import SelectMarketplace from "pages/Shopify/Billing/components/SelectMarketplace";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EbayPlans from "./components/EbayPlans";

const EbayLocked = () => {
    const { plans, handlePlanChange } = usePlans("yearly");
    const [pageType, setPageType] = useState<"couponUsed" | "alreadyTrial" | null>(null);
    const user = useDecodeToken();
    const ebayAlreadyTrial = user.already_trial === "1";
    const isCouponUsed = pageType === "couponUsed"
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("CouponUsed")) {
            setPageType("couponUsed");
        }

        if (searchParams.get("AlreadyTrial") || ebayAlreadyTrial) {
            setPageType("alreadyTrial");
        }

    }, [searchParams, ebayAlreadyTrial]);

    return (
        <div>
            <BillingHeader />
            <BillingBackButton>
                <div className="flex flex-col w-full">
                    {pageType !== null ? <div className="px-[22px] mb-3">
                        <h1 className="text-center text-[28px] max-lg:text-[24px] max-sm:text-[18px] font-bold text-errorRed mb-4 max-lg:mb-[30px] max-sm:mb-3">
                            {isCouponUsed ? <span>You have already used this Coupon. <br className='max-lg:hidden' /> As this exclusive Coupon has been used, the full subscription price will be applied.</span> :
                                "You already used a Trial before, please pick a Plan to continue with a Subscription!"} </h1>
                        <h2 className="text-center font-meium text-[24px] max-lg:text-[20px] max-sm:text-[16px]">{isCouponUsed ?
                            "Please select a plan to start your subscription." :
                            <span>In order to continue using ZIK Analytics, please complete payment process below.<br className='max-lg:hidden' /> Since you have used a free trial before, the full plan subscription price will be changed.</span>}
                        </h2>
                    </div> : <h4 className="text-center text-lg font-medium dark:text-grey100 mb-4">Pick your plan</h4>}
                    <h5 className="text-center font-bold dark:text-grey100 mb-4">Select your Marketplace</h5>
                    <SelectMarketplace marketplace="ebay" hideShopify onMarketplaceChange={() => { }} wrapperClassName="mb-[26px]" />
                    <PlansDiscountTxt activePlans="ebay" period={plans.period} />
                    <PlanSwitch
                        activePlan={plans.period}
                        handlePlanChange={handlePlanChange}
                        longSwitchStyles="py-2.5 max-w-[1010px]"
                        showTwoPlans={plans.planList.length === 2}
                    />
                </div>
            </BillingBackButton>

            <EbayPlans isPayNow={pageType !== null} />
            <Toaster />
        </div>
    )
}

export default EbayLocked;