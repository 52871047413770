import { FC } from "react";
import DaysCounter from "./DaysCounter";


interface Props {
    className?: string;
    activePlans: "shopify" | "ebay";
    fromRegFunnel?: boolean;
    period?: string;
    pulsing?: boolean;
}
const PlansDiscountTxt: FC<Props> = ({ className, fromRegFunnel = false, pulsing }) => {
    return (
        <div>
            <div className={`flex gap-4 justify-center items-center max-sm:flex-col ${fromRegFunnel ? "" : "flex-col"} ${className}`}>
                <div className="flex items-center gap-4 max-sm:flex-col">
                    <h6 className="text-[20px] text-grey900 font-bold border border-solid border-[#fff] bg-[#FFF12F] rounded-[8px] leading-[36px] px-[15px] max-sm:text-lg
                                    shadow-[0px_0px_14.656px_0px_#FFF12F,_0px_0px_8.549px_0px_#FFF12F,_0px_0px_4.275px_0px_#FFF12F,_0px_0px_1.221px_0px_#FFF12F,_0px_0px_0.611px_0px_#FFF12F]">
                        {fromRegFunnel ? "Get Started for Half Price!" : "BLACK FRIDAY MEGA DEAL"}</h6>
                    <p className={`${fromRegFunnel ? "hidden" : ""} text-[20px] font-medium max-lg:text-base`}>Don’t Miss Out - Claim Now!</p>
                </div>
                <div className={`flex items-center gap-6 max-sm:gap-4 max-sm:flex-col`}>
                    <h6 className={`${fromRegFunnel ? "hidden" : ""} font-bold text-[24px] max-sm:text-lg`}>50% Off All Plans!</h6>
                    <DaysCounter pulsing={pulsing} />
                </div>
            </div>
        </div>



    )
}

export default PlansDiscountTxt;