import { plansComparison } from "assets/data/plans/plans";
import { FC } from "react";
import { PlansType, TSelectedPlan } from "types/plans";
import PlanComparisonFooter from "./PlanComparisonFooter";
import PlansComparisonItem from "./PlansComparisonItem";

interface Props {
  plans: PlansType;
  hideFooter?: boolean;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  fromRegistration?: boolean;
  contStyles?: string
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  isPayNow?: boolean;
}

const PlansComparison = ({
  plans,
  hideFooter,
  handleSelectPlan,
  selectedPlan = "pro",
  contStyles,
  fromRegistration = false,
  isPayNow,
}: Props) => {
  const showTwoPlans = plans.planList.length === 2
  const flexStyles = (plan?: "pro" | "pro-plus" | "enterprise") => `flex items-center font-bold justify-center ${plan && plan !== selectedPlan ? "max-lg:hidden" : ""} ${plan ? "max-lg:col-span-2" : "max-lg:col-span-4"}`

  return <div className="flex justify-center dark:text-darkBlue w-full">
    <div className={` ${showTwoPlans ? "max-w-[901px]" : "max-w-[1220px]"} max-[1240px]:px-5 max-lg:px-0 max-lg:!max-w-[330px] w-full mt-[15px] ${contStyles || ""}`}>
      <div className="shadow-shadow3 z-10 rounded-[15px] overflow-hidden relative z-[20]">
        <div className={`grid  h-[49px] rounded-t-[15px] bg-darkBlue text-white ${showTwoPlans ? "grid-cols-3 max-lg:grid-cols-4" : "grid-cols-4 max-lg:grid-cols-6"}`}>
          <div className={flexStyles()}>Quick Look</div>
          <div className={flexStyles("pro")}>Pro</div>
          <div className={flexStyles("pro-plus")}>Pro+</div>
          {!showTwoPlans ? (
            <div className={flexStyles("enterprise")}>Enterprise</div>
          ) : ""}
        </div>
        {plansComparison.map((item, i) => (
          <PlansComparisonItem
            key={i}
            showTwoPlans={showTwoPlans}
            selectedPlan={selectedPlan}
            item={item}
            hasBottomDivider={plansComparison.length - 1 !== i}
          />
        ))}
      </div>
      {!hideFooter && (
        <PlanComparisonFooter
          selectedPlan={selectedPlan}
          plans={plans}
          isPayNow={isPayNow}
          handleSelectPlan={handleSelectPlan}
          fromRegistration={fromRegistration}
        />
      )}
    </div>
  </div>

}

export default PlansComparison;


const PlansComparisonResponsive: FC<Props> = (props) => {
  return (
    <div className="w-full">
      <div className="max-lg:hidden w-full">
        <PlansComparison
          {...props} />
      </div>
      <div className="lg:hidden w-full flex">
        <PlansComparison
          {...props} />
        <PlansComparison
          {...props} />
        <PlansComparison
          {...props} />
      </div>
    </div>
  )
}

export { PlansComparisonResponsive };
