import { ReactComponent as ItemFinder } from "assets/icons/boxes/box.svg";
import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";

const planFeatures = [{
  title: "Market Insights",
  Icon: <Logo className="w-[17px]" />,
  desc: "Find the best products to maximize sales"
}, {
  title: "Product Research",
  Icon: <ProductResearch className="w-[15px] fill-primaryPurple dark:fill-purple500" />,
  desc: <span>Find the hottest products and <br />increase your store's profits</span>,
}, {
  title: "Competitor Research",
  Icon: <CompetitorResearch className="fill-primaryPurple dark:fill-purple500 w-[15px]" />,
  desc: <span>Get current intel on competitors <br />products and strategies</span>
},
{
  title: "Category Research",
  Icon: <CategoryResearch className="fill-primaryPurple dark:fill-purple500 w-[15px]" />,
  desc: <span>See top selling items and<br /> sellers in any eBay category</span>
},
{
  title: "Title Builder",
  Icon: <TitleBuilder className="fill-primaryPurple  dark:fill-purple500 w-[15px]" />,
  desc: <span>Know and use all the keywords to drive traffic and sales</span>
},
{
  title: "Item Finder",
  Icon:
    <ItemFinder className="fill-primaryPurple dark:fill-purple500 w-[15px]" />
},
]
const Pro = () => (
  <div className="flex flex-col gap-2 mx-auto">
    <div className="font-medium text-sm text-left px-2">Key Benefits:</div>
    <div className="flex flex-col gap-1.5">
      {planFeatures.map((item, idx) => (
        <div key={idx}>
          <div className="flex gap-[5px] items-center" >
            <div className="flex h-[30px] w-[30px] items-center justify-center">
              {item.Icon}
            </div>
            <p className="text-sm font-bold">{item.title}</p>
          </div>
          <p className="text-xs pl-[35px]">{item.desc}</p>
        </div>

      ))}
    </div>

  </div>
);

export default Pro;
