import { FC, ReactNode } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface Props {
  anchorId: string | number;
  title: ReactNode | string;
  bg?: string;
  place?: "top" | "top-start" | "top-end" | "right" | "right-start" | "right-end" | "bottom" | "bottom-start" | "bottom-end" | "left" | "left-start" | "left-end";
  maxWidth?: string;
  hideArrow?: boolean
  className?: string
}

const Tooltip: FC<Props> = ({ anchorId, title, maxWidth, place, bg, hideArrow, className }) => (
  <ReactTooltip
    id={anchorId?.toString()}
    // @ts-ignore
    place={place}
    classNameArrow={hideArrow ? "bg-transparent" : ""}
    className={`${bg || "bg-darkBlue"
      } opacity-100 px-2.5 pt-2.5 pb-3 rounded-10 !z-[9999] dark:bg-grey700 dark:text-grey100 ${className} `}
  >

    <div
      className={`${maxWidth || "max-w-[250px]"} text-[10px] leading-normal`}

    >
      <span>{title}</span>
    </div>
  </ReactTooltip>
);

export default Tooltip;
