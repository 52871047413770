import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";

import { ColumnDef } from "@tanstack/react-table";
import HoveredIcon from "components/HoveredIcon";
import IconFinderBox from "components/IconFinderBox";
import ProductNameCell from "components/ProductNameCell";
import Tooltip from "components/Tooltip";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { CURRENCY } from "types/currency";
import { EbayProductExplorerList } from "types/services/ebayProductExplorer";
import { CURRENCY_SIGNS, formatCurrency, formatNumber } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";

export const ebayProductExplorerCols = (salesDateRange: string[], handleSort: (column: string) => void): ColumnDef<EbayProductExplorerList>[] => ([
    {
        header: "Image",
        accessorKey: "imageUrl",
        enableSorting: false,
        cell: ({ row: { original } }) => (
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2.5 ml-[13px]">
                    <div className="w-[5rem]">
                        <ProductNameCell
                            img={original.imageUrl}
                            imgWrapperClassName="ml-0"
                            adjustFirstandLastItemsAuto
                            height="59px"
                            width="75px"
                            maxHeight="max-h-[59px]" />
                    </div>
                </div>
            </div>

        ),
        meta: {
            align: "justify-start"
        }
    },
    {
        header: "Product",
        minSize: 350,
        accessorKey: "title",
        cell: ({ row: { original: {
            productUrl,
            isAmzDropshipUS,
            isAmzDropshipUK,
            isWalmartDropship,
            isAliDroppship,
            title
        } } }) => (
            <ProductNameCell
                name={title}
                checkKeywords
                url={productUrl}
                isDropshipper={
                    isAliDroppship ||
                    isAmzDropshipUK ||
                    isWalmartDropship ||
                    isAmzDropshipUS
                }
                isAliDropship={isAliDroppship}
                isAmzDropshipUK={isAmzDropshipUK}
                isWalmartDropship={isWalmartDropship}
                isAmzUSDropship={isAmzDropshipUS}
            />
        ),
        meta: {
            align: "justify-start",
            sortColumnId: "title",
            customSort: () => handleSort("title"),
        }
    },
    {
        header: "Upload Date",
        minSize: 130,
        accessorKey: "uploadDate",
        cell: ({ row: { original } }) => (
            original.uploadDate === null ? "-" :
                dayjs(original.uploadDate).format('D MMM YYYY')

        ),
        meta: {
            align: "justify-start",
            sortColumnId: "uploadDate",
            customSort: () => handleSort("uploadDate"),
        }
    },
    ...(salesDateRange.length > 0 ? salesDateRange.sort((a, b) => Number(a) - Number(b)).map((item) => {
        const salesColKey = item === "30" ? "thirtyDaysSales" : item === "21" ? "twentyOneDaysSales" : item === "14" ? "fourteenDaysSales" : "sevenDaysSales"
        const revenueColKey = item === "30" ? "thirtyDaysRevenue" : item === "21" ? "twentyOneDaysRevenue" : item === "14" ? "fourteenDaysRevenue" : "sevenDaysRevenue"
        return ({
            header: `${item} Day Sales`,
            accessorKey: salesColKey,
            cell: ({ row: { original } }) => (
                <div>
                    <p className="text-xs">{formatNumber(String(original[salesColKey]))}</p>
                    <p className="text-xs font-medium text-grey300">{formatCurrency(String(original[revenueColKey] || 0), CURRENCY.dollar, original.currency as CURRENCY_SIGNS, false)}</p>
                </div>
            ),
            meta: {
                customSort: () => handleSort(salesColKey),
                sortColumnId: salesColKey,
                align: "justify-start",
            },
        } as ColumnDef<EbayProductExplorerList>)
    }) : []),
    {
        header: "Price",
        accessorKey: "currentPrice",
        cell: ({ row: { original } }) => (
            <>
                <div data-tooltip-id={original.itemId + "r002"} className="flex flex-col item-center justify-center">
                    {formatCurrency(original.currentPrice, CURRENCY.dollar, original.currency as CURRENCY_SIGNS)}
                </div>
                <Tooltip anchorId={original.itemId + "r002"} title="Results in USD and your chosen currency" />
            </>
        ),
        meta: {
            align: "justify-start",
            sortColumnId: "currentPrice",
            customSort: () => handleSort("currentPrice"),
        }
    },
    {
        header: () => <CompetitorResearch className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "searchedUserId",
        minSize: 50,
        enableSorting: false,
        cell: ({ row: { original } }) => (
            <Link to={makeQueryParams(PATH.competitorResearch.ebay, { search: original.seller })} target="_blank">
                <HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]" variant="outlined" title="Scan Seller" width="w-[35px]" height="h-[35px]" />
            </Link>
        ),
        meta: {
            alignHead: "justify-center",
            tooltipTitle: "Scan Seller",
            tooltipPosition: "left"
        }
    },
    {
        header: () => <Box className="fill-darkBlue dark:fill-grey100" />,
        accessorKey: "itemID",
        minSize: 50,
        enableSorting: false,
        cell: ({ row: { original } }) => {
            const ali = original.isAliDroppship;
            const wal = original.isWalmartDropship;
            const amzUs = original.isAmzDropshipUS;
            const amzUk = original.isAmzDropshipUK;
            const showAli = amzUk !== true && amzUs !== true && wal !== true;
            const showAmzUk = (ali !== true && amzUk === true && amzUs !== true);
            const showAmzUs = (ali !== true && amzUk !== true && amzUs === true);
            const showWal = amzUk !== true && amzUs !== true && wal === true;

            return (
                <IconFinderBox
                    customMarketplace={showWal ? "WAL" : showAmzUk ? "AmazonUK" : showAmzUs ? "AmazonUS" : "Ali"}
                    openIconFinder={() => { }}
                    isGreen={original.similar === null ? false : true}
                    isSimilar={original.similar || false}
                    sales={original.thirtyDaysSales}
                    currentPrice={original.currentPrice}
                    title={original.title}
                    itemID={original.itemId}
                    images={original.imageUrl}
                    productUrl={original.productUrl}
                    fromPE
                    currency={original.currency as CURRENCY_SIGNS}
                />)
        },
        meta: {
            align: "justify-center",
            alignHead: "justify-center",
            tooltipTitle:
                "Use the item finder to locate products from your chosen supplier. To switch suppliers, go to Quick Settings on the left-side menu",
            tooltipPosition: "left"
        }
    }

])

